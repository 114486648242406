var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('h1',[_c('textloader-component',{attrs:{"textKey":"StudieplanRubrik"}})],1),_c('paragraph-component',[_c('textloader-component',{attrs:{"textKey":"StudieplanBrödtext"}})],1),_c('v-card',{staticClass:"mx-auto mb-4",attrs:{"max-width":"400"}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-magnify","label":"Sök efter kurs","single-line":"","hide-details":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-width":200,"bottom":"","offset-y":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-filter-menu")])],1)]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-card',[_c('v-row',[_c('v-col',{staticClass:"pa-0"},[_c('p',{staticClass:"ml-6 mt-6"},[_vm._v("År")])]),_c('v-col',{staticClass:"pa-0"},[_c('v-select',{staticClass:"mt-4 mr-6",attrs:{"items":_vm.year,"multiple":"","chips":"","dense":"","outlined":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('v-chip',[_c('span',[_vm._v(_vm._s(item))])]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" (+"+_vm._s(_vm.selectedYears.length - 1)+" andra) ")]):_vm._e()]}}]),model:{value:(_vm.selectedYears),callback:function ($$v) {_vm.selectedYears=$$v},expression:"selectedYears"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pa-0"},[_c('p',{staticClass:"ml-6 mt-2"},[_vm._v("Termin")])]),_c('v-col',{staticClass:"pa-0"},[_c('v-select',{staticClass:"mr-6",attrs:{"items":_vm.term,"multiple":"","chips":"","dense":"","outlined":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('v-chip',[_c('span',[_vm._v(_vm._s(item))])]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" (+"+_vm._s(_vm.selectedTerms.length - 1)+" andra) ")]):_vm._e()]}}]),model:{value:(_vm.selectedTerms),callback:function ($$v) {_vm.selectedTerms=$$v},expression:"selectedTerms"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pa-0"},[_c('p',{staticClass:"ml-6 mt-2"},[_vm._v("Period")])]),_c('v-col',{staticClass:"pa-0"},[_c('v-select',{staticClass:"mr-6",attrs:{"items":_vm.period,"multiple":"","chips":"","dense":"","outlined":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('v-chip',[_c('span',[_vm._v(_vm._s(item))])]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" (+"+_vm._s(_vm.selectedPeriods.length - 1)+" andra) ")]):_vm._e()]}}]),model:{value:(_vm.selectedPeriods),callback:function ($$v) {_vm.selectedPeriods=$$v},expression:"selectedPeriods"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pa-0"},[_c('p',{staticClass:"ml-6 mt-2"},[_vm._v("Inriktning")])]),_c('v-col',{staticClass:"pa-0"},[_c('v-select',{staticClass:"mr-6",attrs:{"items":_vm.master,"multiple":"","small-chips":"","dense":"","outlined":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('v-chip',[_vm._v(" "+_vm._s(_vm.truncateText(item, 15))+" ")]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" (+"+_vm._s(_vm.selectedMasters.length - 1)+" andra) ")]):_vm._e()]}}]),model:{value:(_vm.selectedMasters),callback:function ($$v) {_vm.selectedMasters=$$v},expression:"selectedMasters"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pa-0"},[_c('p',{staticClass:"ml-6 mt-2"},[_vm._v("Obligatorisk")])]),_c('v-col',{staticClass:"pa-0"},[_c('v-select',{staticClass:"mr-6",attrs:{"items":_vm.compulsory,"multiple":"","chips":"","dense":"","outlined":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('v-chip',[_c('span',[_vm._v(_vm._s(item))])]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" (+"+_vm._s(_vm.selectedCompulsory.length - 1)+" andra) ")]):_vm._e()]}}]),model:{value:(_vm.selectedCompulsory),callback:function ($$v) {_vm.selectedCompulsory=$$v},expression:"selectedCompulsory"}})],1)],1),_c('v-row',[_c('v-btn',{staticClass:"ml-6 mb-2",on:{"click":_vm.clear}},[_vm._v(" Rensa val ")])],1)],1)],1)],1)],1),_c('v-card',[_c('v-data-table',{staticClass:"overflow-auto",staticStyle:{"height":"60vh"},attrs:{"dense":"","height":"100%","headers":_vm.visibleHeaders,"fixed-header":"","items":_vm.data,"search":_vm.search,"items-per-page":200,"item-class":_vm.itemRowBackground,"mobile-breakpoint":0,"hide-default-footer":""},scopedSlots:_vm._u([_vm._l((_vm.visibleHeaders),function(header){return {key:("item." + (header.value)),fn:function(ref){
var item = ref.item;
return [_c('td',{key:header.value,staticClass:"text-with-scrollbar"},[_vm._v(" "+_vm._s(item[header.value])+" ")])]}}}),{key:"item.master",fn:function(ref){
var item = ref.item;
return [_c('i',{staticClass:"text-with-scrollbar",class:_vm.getColor(item.master)},[_vm._v(" "+_vm._s(item.master)+" ")])]}},{key:"item.course",fn:function(ref){
var item = ref.item;
return [_c('a',{staticClass:"blue--text text--darken-2",attrs:{"target":"_blank","href":item.link}},[_vm._v(" "+_vm._s(item.course)+" ")])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }