<template>
    <v-container>
        <h1> <textloader-component textKey="StudieplanRubrik"/> </h1>
        <paragraph-component><textloader-component textKey="StudieplanBrödtext"/></paragraph-component>
        <v-card class="mx-auto mb-4" max-width="400">
          <v-toolbar
            flat
          >
            <v-text-field
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              label="Sök efter kurs"
              single-line
              hide-details
              clearable
            ></v-text-field>
            <v-menu
              v-model="menu"
              :close-on-content-click="false"
              :nudge-width="200"
              bottom
              offset-y
              left
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon>mdi-filter-menu</v-icon>
                </v-btn>
              </template>
              <v-card>
               <v-row>
                <v-col class="pa-0">
                  <p class="ml-6 mt-6">År</p>
                </v-col>
                <v-col class="pa-0">
                  <v-select
                    class="mt-4 mr-6"
                    v-model="selectedYears"
                    :items="year"
                    multiple
                    chips
                    dense
                    outlined
                  >
                    <template v-slot:selection="{ item, index }">
                      <v-chip v-if="index === 0">
                        <span>{{ item }}</span>
                      </v-chip>
                      <span
                        v-if="index === 1"
                        class="grey--text text-caption"
                      >
                        (+{{ selectedYears.length - 1 }} andra)
                      </span>
                    </template>
                  </v-select>
                </v-col>
               </v-row>
               <v-row>
                <v-col class="pa-0">
                  <p class="ml-6 mt-2">Termin</p>
                </v-col>
                <v-col class="pa-0">
                  <v-select
                    class="mr-6"
                    v-model="selectedTerms"
                    :items="term"
                    multiple
                    chips
                    dense
                    outlined
                  >
                    <template v-slot:selection="{ item, index }">
                      <v-chip v-if="index === 0">
                        <span>{{ item }}</span>
                      </v-chip>
                      <span
                        v-if="index === 1"
                        class="grey--text text-caption"
                      >
                        (+{{ selectedTerms.length - 1 }} andra)
                      </span>
                    </template>
                  </v-select>
                </v-col>
               </v-row>
               <v-row>
                <v-col class="pa-0">
                  <p class="ml-6 mt-2">Period</p>
                </v-col>
                <v-col class="pa-0">
                  <v-select
                    class="mr-6"
                    v-model="selectedPeriods"
                    :items="period"
                    multiple
                    chips
                    dense
                    outlined
                  >
                    <template v-slot:selection="{ item, index }">
                      <v-chip v-if="index === 0">
                        <span>{{ item }}</span>
                      </v-chip>
                      <span
                        v-if="index === 1"
                        class="grey--text text-caption"
                      >
                        (+{{ selectedPeriods.length - 1 }} andra)
                      </span>
                    </template>
                  </v-select>
                </v-col>
               </v-row>
               <v-row>
                <v-col class="pa-0">
                  <p class="ml-6 mt-2">Inriktning</p>
                </v-col>
                <v-col class="pa-0">
                  <v-select
                    class="mr-6"
                    v-model="selectedMasters"
                    :items="master"
                    multiple
                    small-chips
                    dense
                    outlined
                  >
                    <template v-slot:selection="{ item, index }">
                      <v-chip v-if="index === 0">
                        {{ truncateText(item, 15) }}
                      </v-chip>
                      <span
                        v-if="index === 1"
                        class="grey--text text-caption"
                      >
                        (+{{ selectedMasters.length - 1 }} andra)
                      </span>
                    </template>
                  </v-select>
                </v-col>
               </v-row>
               <v-row>
                <v-col class="pa-0">
                  <p class="ml-6 mt-2">Obligatorisk</p>
                </v-col>
                <v-col class="pa-0">
                  <v-select
                   class="mr-6"
                    v-model="selectedCompulsory"
                    :items="compulsory"
                    multiple
                    chips
                    dense
                    outlined
                  >
                    <template v-slot:selection="{ item, index }">
                      <v-chip v-if="index === 0">
                        <span>{{ item }}</span>
                      </v-chip>
                      <span
                        v-if="index === 1"
                        class="grey--text text-caption"
                      >
                        (+{{ selectedCompulsory.length - 1 }} andra)
                      </span>
                    </template>
                  </v-select>
                </v-col>
               </v-row>
               <v-row>
                <v-btn
                  @click="clear"
                  class="ml-6 mb-2"
                >
                  Rensa val
                </v-btn>
               </v-row>
              </v-card>
            </v-menu>
          </v-toolbar>
        </v-card>
        <v-card>
          <v-data-table
            dense
            height="100%"
            :headers="visibleHeaders"
            fixed-header
            :items="data"
            :search="search"
            :items-per-page="200"
            :item-class="itemRowBackground"
            :mobile-breakpoint="0"
            style="height: 60vh"
            hide-default-footer
            class="overflow-auto"
            >
            <template v-for="header in visibleHeaders" v-slot:[`item.${header.value}`]="{ item }">
              <td :key="header.value" class="text-with-scrollbar">
                {{ item[header.value] }}
              </td>
            </template>
            <template v-slot:item.master="{ item }">
              <i :class="getColor(item.master)" class="text-with-scrollbar">
                {{ item.master }}
              </i>
            </template>
            <template #item.course="{ item }">
              <a target="_blank" v-bind:href="item.link" class="blue--text text--darken-2">
                {{ item.course }}
              </a>
            </template>
          </v-data-table>
        </v-card>
    </v-container>
</template>

<script>
import ParagraphComponent from '@/components/ParagraphComponent'
import TextloaderComponent from '@/components/TextloaderComponent'
export default {
  components: {
    ParagraphComponent,
    TextloaderComponent
  },
  data: () => {
    return {
      data: [],
      search: '',
      menu: false,
      columnVisibility: {
        year: true,
        term: true,
        period: true,
        master: true,
        compulsory: true,
        course: true
      },
      year: [1, 2, 3, 4, 5],
      selectedYears: [],
      term: ['Termin 1', 'Termin 2', 'Termin 3', 'Termin 4', 'Termin 5', 'Termin 6', 'Termin 7', 'Termin 8', 'Termin 9', 'Termin 10'],
      selectedTerms: [],
      period: ['Period 1', 'Period 2', 'Period 3', 'Period 4'],
      selectedPeriods: [],
      master: ['Årskurs 1-3', 'Beräkningsteknik / Artificiell intelligens', 'Beräkningsteknik / Tillämpad beräkningsteknik', 'Hållbar energiteknik', 'Inbyggda system / Hårdvara för Inbyggda system', 'Inbyggda system / Systemteknik', 'Tillämpad fysik / Kvantteknologi', 'Tillämpad fysik / Fysik', 'Övriga valbara kurser'],
      selectedMasters: [],
      compulsory: ['Ja', 'Nej'],
      selectedCompulsory: []
    }
  },
  created: function () {
    this.data = require('@/assets/Studieplan/Kurser2024.json')
  },
  computed: {
    visibleHeaders () {
      return this.headers.filter(header => this.columnVisibility[header.value])
    },
    headers () {
      return [
        {
          text: 'Kurs',
          value: 'course',
          sortable: false
        },
        {
          text: 'År',
          value: 'year',
          sortable: false,
          filter: value => {
            if (this.selectedYears.length === 0) {
              return true
            } else if (this.selectedYears.includes(value)) {
              return true
            }
          }
        },
        {
          text: 'Termin',
          value: 'term',
          sortable: false,
          filter: value => {
            if (this.selectedTerms.length === 0) { return true }
            return this.selectedTerms.includes(value)
          }
        },
        {
          text: 'Period',
          value: 'period',
          sortable: false,
          filter: value => {
            if (this.selectedPeriods.length === 0) { return true }
            return this.selectedPeriods.includes(value)
          }
        },
        {
          text: 'Inriktning',
          value: 'master',
          sortable: true,
          filter: value => {
            if (this.selectedMasters.length === 0) { return true }
            return this.selectedMasters.includes(value)
          }
        },
        {
          text: 'Obligatorisk',
          value: 'compulsory',
          sortable: false,
          filter: value => {
            if (this.selectedCompulsory.length === 0) {
              return true
            } else if (this.selectedCompulsory.includes('Ja') && (value === 'Ja' || value === 'Alternativ obligatorisk')) {
              return true
            } return this.selectedCompulsory.includes(value)
          }
        }
      ]
    }
  },
  methods: {
    getColor (Inriktning) {
      if (Inriktning === 'Övriga valbara kurser') return 'blue-grey--text'
      else if (Inriktning.includes('Beräkningsteknik')) return 'red--text lighten-2--text'
      else if (Inriktning.includes('Inbyggda')) return 'orange--text'
      else if (Inriktning.includes('fysik')) return 'cyan--text'
      else if (Inriktning.includes('Hållbar')) return 'teal--text darken-3--text'
      else return 'black--text'
    },
    clear () {
      this.selectedYears = []
      this.selectedTerms = []
      this.selectedPeriods = []
      this.selectedMasters = []
      this.selectedCompulsory = []
    },
    visible () {
      for (const header in this.columnVisibility) {
        this.columnVisibility[header] = false
      }
      if (this.selectedItems.length === 0) {
        for (const header in this.columnVisibility) {
          this.columnVisibility[header] = true
        }
      } else {
        for (let i = 0; i < this.selectedItems.length; i++) {
          const selectedItem = this.selectedItems[i]
          this.columnVisibility[selectedItem] = true
          console.log(this.columnVisibility)
        }
      }
    },
    truncateText (text, maxLength) {
      return text.length > maxLength ? text.slice(0, maxLength) + '...' : text
    },
    itemRowBackground: function (item) {
      return (item.period === 'Period 1' || item.period === 'Period 3') ? 'style-1' : 'style-2'
    }
  }
}
</script>

<style>
.style-1 {
  background-color: seashell;
}
.style-2 {
  background-color: snow;
}
.text-with-scrollbar {
  white-space: nowrap;
  overflow: auto;
}
</style>
